import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(nuxtApp => {
  window.dataLayer = window.dataLayer ?? [];
  const { gtm } = useRuntimeConfig().public;

  if (!gtm.id) {
    return;
  }

  nuxtApp.vueApp.use(
    createGtm({
      id: gtm.id,
      defer: false,
      compatibility: false,
      enabled: gtm.enabled,
      debug: gtm.debug,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false
    })
  );
});
